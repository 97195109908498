<template>
    <q-page class="flex flex-center row">
        <q-table
            :loading="loading"
            :rows="rows"
            :columns="columns"
            row-key="uuid"
            flat
            :rows-per-page-options="[10, 25]"
        >
            <template v-slot:top="props">
                <q-space />
                <q-btn icon="refresh" @click="fetch" />
            </template>
        </q-table>
    </q-page>
</template>

<script>
import PaymentCreationForm from "@/components/PaymentCreationForm";
import Payment from "@/models/user/payment.js";
import Api from "@/utils/api.js";

const STATE_TO_LABEL = {
    0: "En attente",
    1: "Envoyé à Stripe",
    2: "En cours de prélèvement",
    3: "Succès",
    4: "Echoué",
};

export default {
    name: 'Payments',
    components: {
        PaymentCreationForm
    },
    data () {
        return {
            rows: [],
            columns: [{
                field: "created_at",
                name: "created_at",
                label: "Date de création",
                format: (val, row) => val.calendar(),
                sort: (a, b, rowA, rowB) => b.format("X") - a.format("X"),
                sortable: true
            }, {
                field: "planned_charge_date",
                name: "planned_charge_date",
                label: "Date de prélévement (estimée)",
                format: (val, row) => JSON.stringify(val) === "null" ? "": val.fromNow(),
                sortable: true
            }, {
                field: "state",
                name: "state",
                label: "Etat",
                format: (val, row) => STATE_TO_LABEL[val],
                sort: (a, b, rowA, rowB) => b.format("X") - a.format("X"),
                sortable: true
            }, {
                field: "description",
                name: "description",
                label: "Description"
            }, {
                field: "amount",
                name: "amount",
                label: "Montant",
                format: (val, row) => val.toFixed(2) + " €"
            }],
            loading: true,
            creation_dialog: false
        };
    },
    mounted () {
        this.fetch();
    },
    methods: {
        async fetch () {
            this.loading = true;
            this.rows = await Api.post({
                handler: "user.my_payments"
            });
            this.loading = false;
        }
    }
}
</script>